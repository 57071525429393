.ant-modal-content {
  max-height: 80vh;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 8px; /* largura da barra de rolagem */
}

::-webkit-scrollbar-track {
  background-color: unset;
}

::-webkit-scrollbar-thumb {
  background-color: #cccccc; /* cor do botão da barra de rolagem */
  border-radius: 4px; /* raio da borda do botão da barra de rolagem */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3818d9; /* cor do botão da barra de rolagem quando hover */
}
